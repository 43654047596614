import React from 'react'
import { graphql } from 'gatsby'
import { ScrollWrapper } from '../components/Scroll/index.style'
import SEO from '../components/SEO'
import { colors } from '../styles/vars/colors.style'
import ScrollSection from '../components/ScrollSection'
import PageTitle from '../components/PageTitle'
import PageIntro from '../components/PageIntro'
import PageLinks from '../components/PageLinks'

const IndexPage = ({ data }) => {
  const {
    title,
    seoDescription,
    introText,
    artists,
  } = data.allContentfulPageArtists.nodes[0]
  return (
    <>
      <SEO title={title} description={seoDescription.seoDescription} />
      <ScrollWrapper color={colors.artists}>
        <ScrollSection width={970}>
          <PageTitle title="Artists" />
        </ScrollSection>

        <ScrollSection width={540}>
          <PageIntro text={introText} showContactFooter={false} />
        </ScrollSection>

        <ScrollSection width={1336}>
          <PageLinks
            type="artists"
            links={artists}
            labelA="The LAAB Artists"
            labelB="& collaborators"
          />
        </ScrollSection>
      </ScrollWrapper>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query ArtistsPageQuery {
    allContentfulPageArtists {
      nodes {
        title
        seoDescription {
          seoDescription
        }
        introText {
          raw
        }
        artists {
          slug
          name
        }
      }
    }
  }
`
